import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
} from '@makeably/creativex-design-system';
import HelperTextInput from 'components/molecules/HelperTextInput';
import { addToast } from 'components/organisms/Toasts';
import {
  post,
} from 'utilities/requests';
import {
  updateScoresAdminCompanyScoresPath,
} from 'utilities/routes';
import styles from './Scores.module.css';

const propTypes = {
  companyId: PropTypes.string.isRequired,
  existingScoreCount: PropTypes.number.isRequired,
  maxScores: PropTypes.number.isRequired,
};

function Scores({
  maxScores: initialMaxScores,
  companyId,
  existingScoreCount,
}) {
  const [newMaxScores, setNewMaxScores] = useState(initialMaxScores);
  const [maxScores, setMaxScores] = useState(initialMaxScores);
  const [error, setError] = useState([]);

  async function changeScores() {
    if (parseInt(newMaxScores) < existingScoreCount) {
      setError([{ value: 'This number cannot be lower than the amount of active scores.' }]);
      return;
    }

    setError([]);
    const url = updateScoresAdminCompanyScoresPath(companyId);

    const response = await post(url, { scores: newMaxScores });

    if (response.data.error) {
      addToast('Something went wrong, please try again', { type: 'error' });
    } else {
      addToast('Successfully updated', { type: 'success' });
      setMaxScores(newMaxScores);
    }
  }

  return (
    <div className="col-8">
      <Card>
        <h5 className={styles.title}>Select the number of scores allowed.</h5>
        <HelperTextInput
          helperText={error}
          label="Max number of scores"
          max={100}
          min={0}
          type="number"
          value={newMaxScores}
          onChange={setNewMaxScores}
        />
        <div className="u-flexRow u-justifyEnd">
          <Button
            disabled={maxScores === parseInt(newMaxScores)}
            label="Update"
            onClick={() => changeScores()}
          />
        </div>
      </Card>
    </div>
  );
}

Scores.propTypes = propTypes;

export default Scores;
